// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Private, Set } from '@redwoodjs/router'

import { useAuth } from './auth'
import SiteLayout from './layouts/SiteLayout/SiteLayout'
import LoggedInLayout from './layouts/LoggedInLayout/LoggedInLayout'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route path="/enterprise" page={EnterprisePage} name="enterprise" />
      <Set wrap={SiteLayout}>
        <Route path="/privacy-policy" page={PrivacyPolicyPage} name="privacyPolicy" />
        <Route path="/terms-of-service" page={TermsOfServicePage} name="termsOfService" />
        <Route path="/" page={HomePage} name="home" />
        <Route path="/product" page={ProductPage} name="product" />

        <Route path="/s/subscribe" page={SubscribePage} name="subscribe" />
        <Route path="/s/checkout/cancelled" page={CheckoutCancelledPage} name="checkoutCancelled" />
        <Route path="/s/checkout/success/{session_id}" page={CheckoutSuccessPage} name="checkoutSuccess" />

        <Route path="/faq" page={FAQPage} name="faq" />
        <Route path="/about" page={AboutPage} name="about" />
        <Route path="/profile" page={ProfilePage} name="profile" />
        <Route path="/not/so/super/duper/secret/hello" page={HomePageSecretPage} name="homePageSecret" />

        <Private unauthenticated="home">
          <Set wrap={LoggedInLayout}>
            <Route path="/saved-searches" page={SavedSearchesPage} name="savedSearches" />
            <Route path="/search" page={SearchPage} name="search" />
            <Route path="/search/{q}" page={SearchPage} name="searchWithQuery" />
            <Route path="/search/{q}/{l}" page={SearchPage} name="searchWithQueryAndLocation" />
          </Set>
        </Private>
        <Route notfound page={NotFoundPage} />
      </Set>
    </Router>
  )
}

export default Routes
