import { useMutation, useQuery } from "@redwoodjs/web"
import { useState } from "react"
import { CustomerCategory, Feature, GetSubscriptionsStatusOutput } from "types/graphql"

type LoggedInLayoutProps = {
  children?: React.ReactNode
}

type SubscriptionsStateType = {
  categories: CustomerCategory[]
  features: Feature[]
}

export const SubscriptionState = React.createContext<SubscriptionsStateType>({
  categories: ['FREE'],
  features: [],
})

const WithSubscription = ({ children }: {
  children?: React.ReactNode
}) => {

  const { data, error, loading } = useQuery(gql`
    query getSubscriptionsStatus($input: GetSubscriptionsStatusInput!) {
      getSubscriptionsStatus(input: $input) {
        categories
        features
        subscriptions {
          id
          status
        }
      }
    }
  `, { variables: { input: {} } })

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error!</div>
  }

  const output: GetSubscriptionsStatusOutput = data.getSubscriptionsStatus

  return <SubscriptionState.Provider value={{
    categories: output.categories,
    features: output.features,
  }}>
    {children}
  </SubscriptionState.Provider>
}

const LoggedInLayout = ({ children }: LoggedInLayoutProps) => {
  const [tried, setTried] = useState(false)
  const isInitializedQ = useQuery(gql`
    query isUserInitialized($input: IsUserInitializedInput!) {
      isUserInitialized(input: $input) {
        isInitialized
      }
    }
  `, { variables: { input: {} }, })

  const [initializeUser] = useMutation(gql`
    mutation initializeUser($input: InitializeUserInput!) {
      initializeUser(input: $input) {
        success
        error
      }
    }
    `)

  if (isInitializedQ.loading) {
    return <div>Loading...</div>
  }

  if (isInitializedQ.error) {
    return <div>Error!</div>
  }

  if (!isInitializedQ.data.isUserInitialized.isInitialized) {
    if (tried) {
      return <div>Login unavailable</div>
    }
    setTried(true)
    initializeUser({
      variables: {
        input: {}
      }
    }).then(() => {
      isInitializedQ.refetch()
    })
    return <div>Logging in...</div>
  }

  return <WithSubscription>
    {children}
  </WithSubscription>
}

export default LoggedInLayout
