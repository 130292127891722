import Sentry from 'src/lib/sentry'
import { defaultTheme, Provider } from '@adobe/react-spectrum'

import { RedwoodProvider } from '@redwoodjs/web'
import {
  GraphQLClientConfigProp,
  RedwoodApolloProvider,
} from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'
import { ApolloLink, HttpLink } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'

import { AuthProvider, useAuth } from './auth'

import { Theme } from '@react-types/provider'
import { ColorScheme } from "@react-types/provider";

import { PostHogProvider } from 'posthog-js/react'

import '@spectrum-css/vars/dist/spectrum-global.css'

import '@spectrum-css/vars/dist/spectrum-large.css'
import '@spectrum-css/vars/dist/spectrum-medium.css'

import '@spectrum-css/vars/dist/spectrum-dark.css'
import '@spectrum-css/vars/dist/spectrum-darkest.css'
import '@spectrum-css/vars/dist/spectrum-light.css'

import '@spectrum-css/tokens/dist/index.css'

import '@spectrum-css/button/dist/index-vars.css'
import '@spectrum-css/icon/dist/index-vars.css'
import '@spectrum-css/page/dist/index-vars.css'
import '@spectrum-css/typography/dist/index-vars.css'

import './index.css'
import { useContext, useState } from 'react'
import { set } from 'lodash'

const createRetryLink = (rwLinks: any): ApolloLink => {
  const retryLink = new RetryLink({
    delay: {
      initial: 10_000,
      jitter: true,
    },
    attempts: {
      max: 2, //  this is not completely stopping retries for some reason
    },
  })
  return ApolloLink.from([retryLink, ...rwLinks])
}

const graphQLClientConfig: GraphQLClientConfigProp = {
  link: createRetryLink,
}

const POSTHOG_KEY = process.env.POSTHOG_KEY

type AppStateType = {
  theme?: Theme
  colorScheme?: ColorScheme
  setColorScheme?: (colorScheme: ColorScheme) => void
}

const initialAppState:AppStateType = {
  theme: defaultTheme,
}

export const AppState = React.createContext<AppStateType>(initialAppState)

const App = () => {
  const [appState, setAppState] = useState<AppStateType>({
    ...initialAppState,
    setColorScheme: (colorScheme: ColorScheme) => {
      setAppState({...appState, colorScheme})
    },
  })

  return <Sentry.ErrorBoundary fallback={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AppState.Provider value={appState}>
        <AuthProvider>
          <PostHogProvider apiKey={POSTHOG_KEY} options={{}}>
            <RedwoodApolloProvider
              useAuth={useAuth}
              graphQLClientConfig={graphQLClientConfig}
              >
              <Provider theme={appState.theme ?? defaultTheme} colorScheme={appState.colorScheme}>
                <Routes />
              </Provider>
            </RedwoodApolloProvider>
          </PostHogProvider>
        </AuthProvider>
      </AppState.Provider>
    </RedwoodProvider>
  </Sentry.ErrorBoundary>
}

export default App
