import { ActionButton, Content, ContextualHelp, Flex, Grid, Heading, Item, Menu, MenuTrigger, SearchField, Text, View, useProvider } from "@adobe/react-spectrum";
import { ColorScheme } from "@react-types/provider";
import { navigate, routes } from "@redwoodjs/router";
import Light from '@spectrum-icons/workflow/Light';
import Moon from '@spectrum-icons/workflow/Moon';
import RealTimeCustomerProfile from '@spectrum-icons/workflow/RealTimeCustomerProfile';
import { usePostHog } from 'posthog-js/react';
import { CSSProperties, useContext } from "react";
import { AppState } from "src/App";
import { useAuth } from "src/auth";
import UserFeedback from "src/components/UserFeedback/UserFeedback";
import { constructQueryUrl } from "src/lib/query";
import { localSignOut } from "src/lib/session";
import { PersonalizedResultSet, SearchInput } from "types/graphql";

export const CONTACT_FORM = "https://forms.gle/z8xcJeebE7H9yD7A7"
export const FEEDBACK_FORM = "https://forms.gle/4YYf6T6PqW7VKaBr8"
export const SALES_FORM = "https://forms.gle/4WnWeAkV2SCD5LJv6"

type SiteLayoutProps = {
  children?: React.ReactNode
}

type LinkProps = {
  text?: string,
} & ({
  url?: string,
  destination?: never,
} | {
  url?: never,
  destination?: string,
})

const TopMenuItem = ({
  url,
  destination,
  text
}: LinkProps
) => {
  const headingCommon: CSSProperties = {
    whiteSpace: 'nowrap',
    // margin: '0px 0px 0px 0px',
    marginLeft: '1em',
    marginRight: '1em',
    marginTop: '1px',
    marginBottom: '1px',
  }

  if (destination || url) {
    const onPress = () => {
      if (destination) {
        navigate(destination)
      } else {
        window.open(url, '_blank')
      }
    }
    return (
      <ActionButton isQuiet onPress={onPress} >
        <h3 style={headingCommon}>
          {text}
        </h3>
      </ActionButton>
    )
  }
  return <h3 style={headingCommon}  >
    {text}
  </h3>
}

const SiteHeader = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  let { colorScheme } = useProvider();
  const appContext = useContext(AppState)
  let themeIcons = { dark: <Moon />, light: <Light /> };
  let otherScheme: ColorScheme = colorScheme === "light" ? "dark" : "light";

  let [searchValue, setSearchValue] = React.useState('');
  const doQuickSearch = () => {
    if (searchValue?.trim().length > 0) {
      const searchInput: SearchInput = {
        quickSearch: searchValue,
      }

      window.location.href = constructQueryUrl(searchInput)
    }
  }

  const padMenuBecauseAlignedDoesntLookAligned = '0.2em'

  return <Grid areas={['logo logo', 'menu account']}
    justifyContent='space-between'
    rowGap='size-200'
  >
    <View gridArea='logo'>
      <h1 style={{
        whiteSpace: 'nowrap',
        margin: '0px 0px 0px 0px',
      }} onClick={() => navigate(routes.home())} >College Power Search</h1>
    </View>
    <View gridArea='menu' paddingStart={padMenuBecauseAlignedDoesntLookAligned}>
      <Flex direction='row' columnGap={{ 'S': 'size-100', 'M': 'size-300' }} justifyContent='space-evenly'>
        <View>
          <TopMenuItem destination={routes.search()} text='Power Search' />
        </View>
        <View>
          <TopMenuItem destination={routes.product()} text='Product' />
        </View>
        <View>
          <TopMenuItem destination={routes.subscribe()} text='Subscribe' />
        </View>
        <View>
          <TopMenuItem url={FEEDBACK_FORM} text='Feedback' />
        </View>
        <View>
          <TopMenuItem url={'https://collegepowersearch.posthaven.com/'} text='Blog' />
        </View>
        <View>
          <SearchField width='size-3000'
            aria-label="Search for a school by name or ID (IPEDS or OPE)"
            labelPosition="side"
            isDisabled={!isAuthenticated}
            value={searchValue}
            onChange={setSearchValue}
            onSubmit={doQuickSearch}
          />
          {
            false &&
            //  having some width and wrapping issues
            <ContextualHelp variant="info" marginTop='size-65' marginStart='size-65'>
              <Heading>Quick search</Heading>
              <Content>
                Enter a name or ID (IPEDS or OPE)
              </Content>
            </ContextualHelp>
          }

        </View>
      </Flex>
    </View>
    <View gridArea='account' paddingEnd='4em'>
      {
        isAuthenticated &&
        <Flex direction='row' columnGap='size-100'>
          <View>
            <MenuTrigger>
              <ActionButton isQuiet >
                <RealTimeCustomerProfile />
                <Text isHidden={{ 'base': true, 'S': false }}>
                  <h3 style={{
                    whiteSpace: 'nowrap',
                    margin: '2px 0px 0px 0px',
                  }} >
                    Account
                  </h3>
                </Text>
              </ActionButton>
              <Menu onAction={k => {
                switch (k) {
                  case 'savedSearches':
                    navigate(routes.savedSearches())
                    break
                  case 'blockedSchools':
                  case 'savedSchools':
                    const personalizedResultSet: PersonalizedResultSet = {}
                    if (k == 'blockedSchools') {
                      personalizedResultSet.blocked = 'ONLY'
                    } else if (k == 'savedSchools') {
                      personalizedResultSet.bookmarked = 'ONLY'
                    }
                    navigate(constructQueryUrl({ personalizedResultSet }))
                    break

                  case 'signOut':
                    localSignOut()
                }
              }}>
                <Item key='savedSearches'>Saved searches</Item>
                <Item key='savedSchools'>Saved schools</Item>
                <Item key='blockedSchools'>Blocked schools</Item>
                <Item key='profile'>Profile</Item>
                <Item key="signOut">
                  Sign out
                </Item>
              </Menu>
            </MenuTrigger>
          </View>
          <View>
            <ActionButton isQuiet marginTop='1px'
              aria-label={`Switch to ${otherScheme} mode.`}
              onPress={() => appContext.setColorScheme?.(otherScheme)}
            >
              {themeIcons[otherScheme]}
            </ActionButton>
          </View>
        </Flex>
      }
    </View>
  </Grid>
}

const FooterLink = ({ text, destination, url }: LinkProps) => {
  return <a href={url ?? destination} className="footerLink" target={ url ? "_blank" : "_self"  }  >
    {text}
  </a>
}

const SiteLayout = ({ children }: SiteLayoutProps) => {
  const { currentUser, isAuthenticated } = useAuth()
  const posthog = usePostHog()

  if (currentUser?.email) {
    const email = currentUser?.email as string
    posthog?.identify(email, {
      'email': currentUser?.email,
    })
  }
  posthog?.setPersonPropertiesForFlags({ 'email': currentUser?.email })


  return <>
    <View isHidden={{ 'base': true, 'S': false }} height='size-1200'
      paddingStart='1.5em'
      paddingEnd='2em'
      paddingBottom='0.6em'
      position="sticky"

      width='100%'
      left={0}
      top={0}

      backgroundColor='gray-50'
      zIndex={999}
      borderBottomColor='gray-600'
      borderBottomWidth='thin'

    >
      <SiteHeader isAuthenticated={isAuthenticated} />
    </View>
    <Flex direction="column">
      <View isHidden={{ 'base': false, 'S': true }} height='size-800'>
        <h2>College Power Search</h2>
      </View>
      {/* UserFeedback view size to match facets */}
    {/* <View marginStart='size-200' padding='size-150' width={{'base':'size-3600','S':'80%'}} colorVersion={6} backgroundColor='blue-200'>
      <UserFeedback />
    </View> */}

      <View marginTop='size-10' marginEnd='size-600' padding='size-200'>
        {children}
      </View>

      <View paddingTop='size-400'
        paddingBottom='size-300'
        width='100%'
        marginTop='size-400'
        borderTopColor='mid'
        borderTopWidth='thin'
        backgroundColor='gray-200'
      >
        <Flex direction='row' columnGap='size-300' justifyContent='space-evenly'>
          <View>
            <FooterLink destination={routes.faq()} text='FAQ' />
          </View>
          <View>
            <FooterLink destination={routes.about()} text='About' />
          </View>
          <View>
            <FooterLink url={CONTACT_FORM} text='Contact' />
          </View>
           <View>
            <FooterLink destination={routes.privacyPolicy()} text='Privacy Policy' />
          </View>
          <View>
            <FooterLink destination={routes.termsOfService()} text='Terms of Service' />
          </View>
        </Flex>
      </View>

    </Flex>
  </>
}

export default SiteLayout
