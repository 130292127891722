import JsonURL from "@jsonurl/jsonurl"
import { routes } from "@redwoodjs/router"
import { SearchInput } from "types/graphql"

export const JSON_URL_OPTIONS = { AQF: true }

export const constructQueryUrl = (searchInput:SearchInput) => {
  const q = JsonURL.stringify(searchInput, JSON_URL_OPTIONS)
  const qparams = q ? { q } : { q: '' }
  const url = q ? routes.search(qparams) : routes.search()

  return url
}